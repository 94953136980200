import { Card, Row } from "react-bootstrap";
import { ConvertToCurrency } from "../functions/ConvertToCurrency";
import { ConvertToPercent } from "../functions/ConvertToPercent";
import { getReqOpt_POST, runFetch, spURL_POST } from "../functions/RunFetch";
import NoImgAvail from "../assets/img/NoImgAvailable.jpg";
import { useState, useEffect, PropsWithoutRef } from "react";
import CommentPopup from "./PopupComment";
import { PriceChangeItemObj } from "../pages/PriceChange/PriceChange";

interface ItemCardLargeProps {
  Mode: string;
  ItemNo: string;
  ImgURL: string;
  Description: string;
  SupplierAccount: string;
  Manufacturer: string;
  ReorderNumber: string;
  Category: string;
  SubCategory: string;
  Segment: string;
  ItemPlanning: string;
  PrivateLabel: string;
  Seasonality: string;
  RangeStatus: string;
  ItemType: string;
  MarginType: string;
  LastPO: string;
  AvgCost: number;
  LastPOCost: number;
  Price: number;
  ForeignCost: number;
  MSRP: number;
  CloseoutPrice: number;
  MarginP: number;
  SellThrough: number;
  SelloutMarginP: number;
  Age: string;
  ReviewForecast: number;
  ReviewForecastRetail: number;
  TradingForecast: number;
  TradingForecastRetail: number;
  Forecast52W: number;
  Forecast52WRetail: number;
  QtySoldL7D: number;
  SOHQty: number;
  SOHRetail: number;
  CommittedQty: number;
  CommittedRetail: number;
  SOHStoreQty: number;
  SOHStoreRetail: number;
  SupplierHoldQty: number;
  SupplierHoldRetail: number;
  ShortMaxUnits: number;
  CatOTBAfterMonth: number;
  MarginDifference: number;
  Order: number;
  NewCommit: number;
  SoldLWQty: number;
  SoldLWRetail: number;
  SoldLMQty: number;
  SoldLMRetail: number;
  SoldYTDQty: number;
  SoldYTDRetail: number;
  SoldL52WQty: number;
  SoldL52WRetail: number;
  SoldLYQty: number;
  SoldLYRetail: number;
  SoldH2HQty: number;
  SoldH2HRetail: number;
  MaxInstockL4W: number;
  SOHWOC: number;
  CommittedWOC: number;
  RankLW: number;
  RankLM: number;
  RankYTD: number;
  RankLY: number;
  RankH2H: number;
  RankRRF: number;
  RankTAF: number;
  Rank52F: number;
  LeadCompName: string;
  LeadCompPrice: number;
  NPDStatus: string;
  NPDUnitsLW: number;
  NPDMarketShareL4W: number;
  NPDMarketShareL13W: number;
  IsSelected: boolean;
  PriceChangeType: string;
  NewSellPrice: number;
  BuyerComment: any;
  Approved: number;
  ToggleCardSelect: (itemno: string) => void;
  ChangeNewSellPrice?: (itemno: string, newprice: number) => void;
  AddComment?: () => void;
  UpdatePriceChangeItemList?: (itemno: string, newbuyercomment: string) => void;
}

function ItemCardLarge({
  Mode,
  ItemNo,
  ImgURL,
  Description,
  SupplierAccount,
  Manufacturer,
  ReorderNumber,
  Category,
  SubCategory,
  Segment,
  ItemPlanning,
  PrivateLabel,
  Seasonality,
  RangeStatus,
  ItemType,
  MarginType,
  LastPO,
  AvgCost,
  LastPOCost,
  Price,
  ForeignCost,
  MSRP,
  CloseoutPrice,
  MarginP,
  SellThrough,
  SelloutMarginP,
  Age,
  ReviewForecast,
  ReviewForecastRetail,
  TradingForecast,
  TradingForecastRetail,
  Forecast52W,
  Forecast52WRetail,
  QtySoldL7D,
  SOHQty,
  SOHRetail,
  CommittedQty,
  CommittedRetail,
  SOHStoreQty,
  SOHStoreRetail,
  SupplierHoldQty,
  SupplierHoldRetail,
  ShortMaxUnits,
  CatOTBAfterMonth,
  MarginDifference,
  Order,
  NewCommit,
  SoldLWQty,
  SoldLWRetail,
  SoldLMQty,
  SoldLMRetail,
  SoldYTDQty,
  SoldYTDRetail,
  SoldL52WQty,
  SoldL52WRetail,
  SoldLYQty,
  SoldLYRetail,
  SoldH2HQty,
  SoldH2HRetail,
  MaxInstockL4W,
  SOHWOC,
  CommittedWOC,
  RankLW,
  RankLM,
  RankYTD,
  RankLY,
  RankH2H,
  RankRRF,
  RankTAF,
  Rank52F,
  LeadCompName,
  LeadCompPrice,
  NPDStatus,
  NPDUnitsLW,
  NPDMarketShareL4W,
  NPDMarketShareL13W,
  PriceChangeType,
  NewSellPrice,
  BuyerComment,
  Approved,
  IsSelected,
  ToggleCardSelect,
  ChangeNewSellPrice,
  UpdatePriceChangeItemList,
}: ItemCardLargeProps) {
  const [showComments, setShowComments] = useState<boolean>(false);

  const handleClick_Card = () => {
    ToggleCardSelect(ItemNo);
  };

  const renderCardData = () => {
    let componentCard;
    switch (Mode) {
      case "Range Review":
        componentCard = (
          <div className="card-large-container">
            <div
              className="card-large-title"
              onClick={() => {
                window.open(`/DetailsPage/${ItemNo}`, "_blank");
              }}
            >
              <div>
                <p>{Description}</p>
                <p>
                  {RangeStatus} {ItemType !== "CLOSEOUT" ? "" : ItemType}{" "}
                  <span className="color-darkviolet">
                    {(PrivateLabel = "" ? "" : PrivateLabel)}
                  </span>
                </p>
                <p className="color-dodgerblue">{MarginP}%</p>
              </div>
              <div>
                <p className="price">{ConvertToCurrency(Price)}</p>
                <p className="item-number">
                  <span>
                    LPO:{ConvertToCurrency(LastPOCost)} AVG:{ConvertToCurrency(AvgCost)}{" "}
                    {ForeignCost === 0 ? "" : "USD:" + ConvertToCurrency(ForeignCost)}
                  </span>
                  {/* <span className="color-dodgerblue">{MarginP}%</span> */}
                  <span>
                    {MSRP === 0 ? "" : "MSRP:" + ConvertToCurrency(MSRP)}{" "}
                    {CloseoutPrice === 0 || CloseoutPrice === Price
                      ? ""
                      : "CS:" + ConvertToCurrency(CloseoutPrice)}
                  </span>
                </p>
                <div>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="card-large-body">
              <div className="card-large-body-row-table">
                <div className="card-large-body-column">
                  <div>
                    <p>{ItemNo}</p>
                  </div>
                  <div>
                    <p>{ReorderNumber}</p>
                  </div>
                  <div className="color-dodgerblue">
                    <p>{ItemPlanning}</p>
                  </div>

                  <div>
                    <p>{Age}</p>
                  </div>
                  <div>
                    <p>{Seasonality}</p>
                  </div>
                  <div
                    style={{
                      color: MaxInstockL4W < 70 ? "Red" : "#262626",
                    }}
                  >
                    <p>L4W Instock</p>
                    <p>{MaxInstockL4W}%</p>
                  </div>
                  {/* <div>
                    <p>{SupplierAccount}</p>
                  </div>
                  <div>
                    <p>{Manufacturer}</p>
                  </div>
                  <div className="color-darkviolet">
                    <p>{Category}</p>
                  </div>
                  <div className="color-darkviolet">
                    <p>{SubCategory}</p>
                  </div> */}
                </div>
                <table className="card-large-table align-right">
                  <tbody>
                    <tr>
                      <td>
                        <p>LW</p>
                      </td>
                      <td>
                        <p>{SoldLWQty}</p>
                      </td>
                      <td>
                        <p>{ConvertToCurrency(SoldLWRetail, 0)}</p>
                      </td>
                      <td>
                        <p>{RankLW}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>LM</p>
                      </td>
                      <td>
                        <p>{SoldLMQty}</p>
                      </td>
                      <td>
                        <p>{ConvertToCurrency(SoldLMRetail, 0)}</p>
                      </td>
                      <td>
                        <p>{RankLM}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>YTD</p>
                      </td>
                      <td>
                        <p>{SoldYTDQty}</p>
                      </td>
                      <td>
                        <p>{ConvertToCurrency(SoldYTDRetail, 0)}</p>
                      </td>
                      <td>
                        <p>{RankYTD}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>L52W</p>
                      </td>
                      <td>
                        <p>{SoldL52WQty}</p>
                      </td>
                      <td>
                        <p>{ConvertToCurrency(SoldL52WRetail, 0)}</p>
                      </td>
                      <td>
                        <p>{RankLY}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>H2H</p>
                      </td>
                      <td>
                        <p>{SoldH2HQty}</p>
                      </td>
                      <td>
                        <p>{ConvertToCurrency(SoldH2HRetail, 0)}</p>
                      </td>
                      <td>
                        <p>{RankH2H}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>RRF</p>
                      </td>
                      <td>
                        <p>{ReviewForecast}</p>
                      </td>
                      <td>
                        <p>{ConvertToCurrency(ReviewForecastRetail, 0)}</p>
                      </td>
                      <td>
                        <p>{RankRRF}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>TAF</p>
                      </td>
                      <td>
                        <p>{TradingForecast}</p>
                      </td>
                      <td>
                        <p>{ConvertToCurrency(TradingForecastRetail, 0)}</p>
                      </td>
                      <td>
                        <p>{RankTAF}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>52F</p>
                      </td>
                      <td>
                        <p>{Forecast52W}</p>
                      </td>
                      <td>
                        <p>{ConvertToCurrency(Forecast52WRetail, 0)}</p>
                      </td>
                      <td>
                        <p>{Rank52F}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="margin-5"></div>
              <div className="card-large-body-row">
                <div>
                  <table className="card-large-table-SOH">
                    <tbody>
                      <tr>
                        <td>
                          <p>SOH</p>
                        </td>
                        <td>
                          <p>{SOHQty}</p>
                        </td>
                        {/* <td>
                            <p>{ConvertToCurrency(SOHRetail, 0)}</p>
                          </td> */}
                      </tr>
                      <tr>
                        <td>
                          <p>Cmt</p>
                        </td>
                        <td>
                          <p>{CommittedQty}</p>
                        </td>
                        {/* <td>
                            <p>{ConvertToCurrency(CommittedRetail, 0)}</p>
                          </td> */}
                      </tr>
                      <tr>
                        <td>
                          <p>Instore</p>
                        </td>
                        <td>
                          <p>{SOHQty}</p>
                        </td>
                        {/* <td>
                            <p>{ConvertToCurrency(SOHRetail, 0)}</p>
                          </td> */}
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div>
                  <div
                    style={{
                      color: SOHWOC > 52 ? "Red" : SOHWOC <= 6 ? "DodgerBlue" : "#262626",
                    }}
                  >
                    <p>WOC SOH</p>
                    <p>{SOHWOC}</p>
                  </div>
                  <div
                    style={{
                      color:
                        CommittedWOC > 52 ? "Red" : CommittedWOC <= 6 ? "DodgerBlue" : "#262626",
                    }}
                  >
                    <p>WOC Cmt</p>
                    <p>{CommittedWOC}</p>
                  </div>
                </div>
              </div>
              <div className="margin-5"></div>
              <div className="card-large-body-row">
                <span className="span-5050">{SupplierAccount}</span>
                <span className="span-5050">{Manufacturer}</span>
              </div>
              <div className="card-large-body-row">
                <div>
                  <p>{LeadCompName}</p>
                </div>
                <div>
                  <p>{LeadCompPrice === 0 ? "" : ConvertToCurrency(LeadCompPrice, 2)}</p>
                </div>
              </div>
              <div className="card-large-body-row">
                <div>
                  <p>{NPDUnitsLW === 0 ? "" : NPDUnitsLW}</p>
                </div>
                <div>
                  <p>
                    {NPDMarketShareL4W === 0 ? "" : "L4W: " + ConvertToPercent(NPDMarketShareL4W)}
                  </p>
                </div>
                <div>
                  <p>
                    {NPDMarketShareL13W === 0
                      ? ""
                      : "L13W: " + ConvertToPercent(NPDMarketShareL13W)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case "Short Cover":
        break;
      case "Order Approval":
        break;
      case "Source List":
        break;
      case "Best Sellers":
        break;
      case "Markdown Action":
        break;
      case "Price Change":
        componentCard = (
          <div className="card-large-container">
            <div className="card-large-title">
              <div>
                <p className="color-forestgreen">
                  {ConvertToPercent((NewSellPrice / 1.1 - LastPOCost) / (NewSellPrice / 1.1))}
                </p>
                <p>{Description}</p>
                <p>{ItemType}</p>
                <p>{ItemNo}</p>
              </div>
              <div>
                {ChangeNewSellPrice !== undefined && (
                  <>
                    <strong>$</strong>
                    <input
                      type="text"
                      name="NewPrice"
                      className="new-price-input"
                      defaultValue={NewSellPrice}
                      onBlur={(e) => ChangeNewSellPrice(ItemNo, parseFloat(e.target.value))}
                    />
                  </>
                )}
                <p className="price">{ConvertToCurrency(Price)}</p>
                <p className="item-number">
                  <span>
                    LPO:{ConvertToCurrency(LastPOCost)} AVG:{ConvertToCurrency(AvgCost)}{" "}
                    {ForeignCost === 0 ? "" : "USD:" + ConvertToCurrency(ForeignCost)}
                  </span>
                  {/* <span className="color-dodgerblue">{MarginP}%</span> */}
                  <span>
                    {MSRP === 0 ? "" : "MSRP:" + ConvertToCurrency(MSRP)}{" "}
                    {CloseoutPrice === 0 || CloseoutPrice === Price
                      ? ""
                      : "CS:" + ConvertToCurrency(CloseoutPrice)}
                  </span>
                </p>
                <p className="color-dodgerblue">{MarginP}%</p>
                <div>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="card-large-body">
              <div className="card-large-body-row-table">
                <div className="card-large-body-column">
                  <div>
                    <p>{ReorderNumber}</p>
                  </div>
                  <div>
                    <p>{Age}</p>
                  </div>
                  <div
                    style={{
                      color: MaxInstockL4W < 70 ? "Red" : "#262626",
                    }}
                  >
                    <p>L4W Instock</p>
                    <p>{MaxInstockL4W}%</p>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      color: PriceChangeType.includes("Mark Down") ? "Red" : "DodgerBlue",
                    }}
                  >
                    <p>{PriceChangeType}</p>
                  </div>
                  <table className="card-large-table align-right">
                    <tbody>
                      <tr>
                        <td>
                          <p>LW</p>
                        </td>
                        <td>
                          <p>{SoldLWQty}</p>
                        </td>
                        <td>
                          <p>{ConvertToCurrency(SoldLWRetail, 0)}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>LM</p>
                        </td>
                        <td>
                          <p>{SoldLMQty}</p>
                        </td>
                        <td>
                          <p>{ConvertToCurrency(SoldLMRetail, 0)}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>YTD</p>
                        </td>
                        <td>
                          <p>{SoldYTDQty}</p>
                        </td>
                        <td>
                          <p>{ConvertToCurrency(SoldYTDRetail, 0)}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="margin-5"></div>
              <div className="card-large-body-row">
                <div>
                  <table className="card-large-table-SOH">
                    <tbody>
                      <tr>
                        <td>
                          <p>SOH</p>
                        </td>
                        <td>
                          <p>{SOHQty}</p>
                        </td>
                        {/* <td>
                            <p>{ConvertToCurrency(SOHRetail, 0)}</p>
                          </td> */}
                      </tr>
                      <tr>
                        <td>
                          <p>Cmt</p>
                        </td>
                        <td>
                          <p>{CommittedQty}</p>
                        </td>
                        {/* <td>
                            <p>{ConvertToCurrency(CommittedRetail, 0)}</p>
                          </td> */}
                      </tr>
                      <tr>
                        <td>
                          <p>Instore</p>
                        </td>
                        <td>
                          <p>{SOHQty}</p>
                        </td>
                        {/* <td>
                            <p>{ConvertToCurrency(SOHRetail, 0)}</p>
                          </td> */}
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div>
                  <div
                    style={{
                      color: SOHWOC > 52 ? "Red" : SOHWOC <= 6 ? "DodgerBlue" : "#262626",
                    }}
                  >
                    <p>WOC SOH</p>
                    <p>{SOHWOC}</p>
                  </div>
                  <div
                    style={{
                      color:
                        CommittedWOC > 52 ? "Red" : CommittedWOC <= 6 ? "DodgerBlue" : "#262626",
                    }}
                  >
                    <p>WOC Cmt</p>
                    <p>{CommittedWOC}</p>
                  </div>
                </div>
              </div>
              <div className="margin-5"></div>
              <div className="card-large-body-row">
                <span className="span-5050">{SupplierAccount}</span>
                <span className="span-5050">{Manufacturer}</span>
              </div>
              <div className="card-large-body-row">
                <div>
                  <p>{LeadCompName}</p>
                </div>
                <div>
                  <p>{LeadCompPrice === 0 ? "" : ConvertToCurrency(LeadCompPrice, 2)}</p>
                </div>
              </div>
              <div>
                <button onClick={() => setShowComments(true)}>+</button>
              </div>
              {BuyerComment.length > 0 && (
                <table>
                  <tbody>
                    {BuyerComment.map((comment: string, index: number) => (
                      <tr key={index} className="card-large-footer-table">
                        <td>{comment}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <div></div>
            </div>

            <CommentPopup
              show={showComments}
              setShow={setShowComments}
              itemNo={ItemNo}
              UpdatePriceChangeItemList={UpdatePriceChangeItemList}
            />
          </div>
        );
        break;
    }

    return componentCard;
  };

  return (
    // <div
    //   className={`card-large-wrapper${IsSelected ? "-selected" : ""}`}
    //   onClick={handleClick_Card}
    // >
    <>
      <div className="card-large-image-container default-alignment padding-0">
        {ImgURL.length === 0 ? (
          <img
            className="card-large-image"
            loading="lazy"
            src={NoImgAvail}
            alt="No image available"
          />
        ) : (
          <img className="card-large-image" loading="lazy" src={ImgURL} alt="No image available" />
        )}
      </div>
      {renderCardData()}
    </>
    // </div>
  );
}
export default ItemCardLarge;
